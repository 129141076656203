import React, { useState, Fragment } from "react"
import { graphql } from "gatsby"

import styled from "@emotion/styled"
import { Box, Flex } from "@chakra-ui/core"

import Masonry from "containers/masonry"
import Layout from "containers/layout"
import SEO from "components/seo"
// import Ostsee from "./ostsee.inline.svg"
import Karte from "./karte.inline.svg"
import Article from "containers/article"
import EventTeaser from "containers/eventTeaser"
// import Event from "containers/event"
import LinkTo from "containers/link-to"
import Lightbox from "containers/lightbox"

// const StyledOstsee = styled(Ostsee)`
//   width: 18em;
//   height: auto;
//   position: absolute;
//   right: 12em;
//   top: 10em;

//   @media (min-width: 48em) {
//     width: 18em;
//     height: auto;
//     right: 16em;
//     top: 10em;
//   }

//   @media (min-width: 75em) {
//     width: 14em;
//     height: auto;
//     right: 30em;
//     top: 9em;
//   }
// `

export const onlyClient = typeof window !== "undefined"

const StyledKarte = styled(Karte)`
  width: auto;
  height: 22em;
  margin: 1em 1em 5em 0em;

  @media (min-width: 48em) {
    width: auto;
    height: 22em;
    margin: 1em 1em 5em 0em;
  }

  @media (min-width: 75em) {
    width: auto;
    height: 24em;
    margin: 4em 2em 7em 0em;
  }
`

const IndexPage = ({ location, data }) => {
  const [viewLightbox, setViewLightbox] = useState(false)
  const [lightboxData, setLightboxData] = useState({})

  return (
    <Layout path={location.pathname}>
      <SEO
        title={
          data &&
          data.strapi &&
          data.strapi.home &&
          data.strapi.home.meta &&
          data.strapi.home.meta.title
        }
        description={
          data &&
          data.strapi &&
          data.strapi.home &&
          data.strapi.home.meta &&
          data.strapi.home.meta.description
        }
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        minHeight="90vh"
        w="100%"
        pos="relative"
      >
        {onlyClient && (
          <Fragment>
            <Masonry
              breakpointColumns={{
                2: "(min-width: 48em)",
              }}
              gap={["5em", "5em", "4em"]}
              defaultColumn={1}
            >
              {data.strapi.home &&
                data.strapi.home.inhalt_en &&
                data.strapi.home.inhalt_en.map((item, index) => {
                  const images =
                    item.article &&
                    item.article.gallery &&
                    item.article.gallery.map(
                      src => src.imageFile.childImageSharp.fluid
                    )

                  const listItem = item.article ? (
                    <Article
                      key={index}
                      title={item.article.title}
                      text={item.article.text}
                      image={item.article.cover && item.article.cover.imageFile}
                      imageGray={
                        item.article.cover && item.article.cover.imageFileGray
                      }
                      gallery={images && images}
                      setData={setLightboxData}
                      viewLightbox={setViewLightbox}
                      textColor="green"
                    />
                  ) : item.event ? (
                    <EventTeaser
                      key={index}
                      id={item.event.id}
                      date={item.event.date}
                      title={item.event.title}
                      subtitle={item.event.subtitle}
                      image={item.event.cover && item.event.cover.imageFile}
                      imageGray={
                        item.event.cover && item.event.cover.imageFileGray
                      }
                      path={location.pathname}
                    />
                  ) : item.title || item.text ? (
                    <Article
                      key={index}
                      title={item.title}
                      text={item.text}
                      textColor="green"
                      image={item.image && item.image.imageFile}
                      justInformation={item.__typename.includes("Information")}
                    />
                  ) : (
                    item.linkTo && (
                      <LinkTo
                        key={index}
                        linkTo={item.linkTo}
                        data={data.strapi}
                        // link={item.link_to.title}
                        // title={item.link_to.teaser.title}
                        // text={item.link_to.teaser.subtitle}
                        // image={
                        //   item.link_to.teaser.cover &&
                        //   item.link_to.teaser.cover.imageFile
                        // }
                        // imageGray={
                        //   item.link_to.teaser.cover &&
                        //   item.link_to.teaser.cover.imageFileGray
                        // }
                        // path={location.pathname}
                      />
                    )
                  )

                  return listItem
                })}
            </Masonry>
            <Flex w="100%" justify="flex-end">
              <StyledKarte />
            </Flex>
          </Fragment>
        )}
      </Box>
      <Lightbox
        text={lightboxData.text}
        title={lightboxData.title}
        images={lightboxData.gallery}
        visibleFunc={setViewLightbox}
        visible={viewLightbox}
        animate={true}
        viewImageInfo={viewLightbox}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapi {
      home {
        meta {
          description
          title
        }
        inhalt_en {
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              __typename
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageEvent {
            id
            event {
              id
              text
              date
              title
              subtitle
              publish_on_date
              __typename
              cover {
                url
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageLink {
            linkTo
          }
          ... on STRAPI_ComponentPageText {
            text
          }
        }
        inhalt_en {
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              __typename
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageEvent {
            id
            event {
              id
              text
              date
              title
              subtitle
              publish_on_date
              __typename
              cover {
                url
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageLink {
            linkTo
          }
          ... on STRAPI_ComponentPageText {
            text
          }
        }
        teaser {
          subtitle
          title
        }
        teaser_en {
          subtitle
          title
        }
      }
      dasHau {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      angeboteDesHause {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      fundraising {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      literaturwettbewerb {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      veranstaltungen {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      freundeskrei {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      impressum {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      forschung {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      buchhandlung {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      datenschutz {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
      kontakt {
        teaser {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
        teaser_en {
          title
          subtitle
          cover {
            url
            __typename
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
            imageFileGray: imageFile {
              childImageSharp {
                fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                  ...GatsbyImageSharpFluid_withWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
